import TextLink from "../../buttons/TextLink/TextLink";
import paths from "routing/paths";
import { StyledFooter } from "./Footer.module";
import Body from "../../typography/Body/Body";

const Footer = () => {
  return (
    <StyledFooter>
      <footer>
        <ul>
          <li>
            <TextLink to={paths.CONTACT_US} className="footer-link">
              Contact Us
            </TextLink>
          </li>
          <li>
            <TextLink to={paths.FEEDBACK} className="footer-link">
              Feedback
            </TextLink>
          </li>
          <li>
            <Body>&copy; {new Date().getFullYear()} Verizon</Body>
          </li>
        </ul>
      </footer>
    </StyledFooter>
  );
};

export default Footer;

import { useState, useEffect } from "react";
import FlexBox from "components/layouts/FlexBox/FlexBox";
import PopularLinkContainer from "../PopularLinkContainer/PopularLinkContainer";
import Title from "components/typography/Title/Title";
import Body from "components/typography/Body/Body";
import Image from "components/layouts/Image/Image";
import { PopularLinkProps } from "../../../interfaces/PopularLinks";

const AnimationTile = ({
  backgroundColor,
  bottomPadding,
  callout,
  hoverImage1Asset,
  hoverImage2Asset,
  hoverImage3Asset,
  baseImageAsset,
  imageAlignment,
  linkUrl,
  title,
  external,
}: PopularLinkProps) => {
  let images = [baseImageAsset];
  if (
    baseImageAsset &&
    hoverImage1Asset &&
    !hoverImage2Asset &&
    !hoverImage3Asset
  ) {
    images = [baseImageAsset, hoverImage1Asset];
  } else if (
    baseImageAsset &&
    hoverImage1Asset &&
    hoverImage2Asset &&
    !hoverImage3Asset
  ) {
    images = [baseImageAsset, hoverImage1Asset, hoverImage2Asset];
  } else if (
    baseImageAsset &&
    hoverImage1Asset &&
    hoverImage2Asset &&
    hoverImage3Asset
  ) {
    images = [
      baseImageAsset,
      hoverImage1Asset,
      hoverImage2Asset,
      hoverImage3Asset,
    ];
  }

  const [activeImage, setActiveImage] = useState<number>(0);
  const [mouseEntered, setMouseEntered] = useState<boolean>(false);

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setActiveImage(images[activeImage + 1] ? activeImage + 1 : 1);
    }, 1200);
    if (!mouseEntered) {
      clearInterval(imageInterval);
    }
    return () => {
      clearInterval(imageInterval);
    };
  }, [mouseEntered, activeImage]);

  const onMouseEnter = () => {
    setActiveImage(1);
    // startCountHandler();

    setMouseEntered(true);
  };

  const onMouseLeave = () => {
    setActiveImage(0);
    setMouseEntered(false);
    // stopCountHandler();
  };

  return (
    <PopularLinkContainer
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      to={linkUrl}
      imageAlignment={imageAlignment}
      bg={backgroundColor}
      external={external}
    >
      <FlexBox row justify="space-between" gap="1rem">
        <Title size="small" bold pd="1.5rem 0 0 1.5rem">
          {title}
        </Title>
        <Title
          bold
          size="small"
          color="#EE0000"
          pd="1.5rem 1.5rem 0 0"
        >
          {callout}
        </Title>
      </FlexBox>

      {baseImageAsset && (
        <Image
          popularLink
          className={`${activeImage === 0 ? "active" : ""} ${
            bottomPadding ? "bottom-padding" : ""
          }`}
          src={baseImageAsset ? baseImageAsset : ""}
        />
      )}
      {hoverImage1Asset && (
        <Image
          popularLink
          className={`${activeImage === 1 ? "active" : ""} ${
            bottomPadding ? "bottom-padding" : ""
          }`}
          src={hoverImage1Asset ? hoverImage1Asset : ""}
        />
      )}
      {hoverImage2Asset && (
        <Image
          popularLink
          className={`${activeImage === 2 ? "active" : ""} ${
            bottomPadding ? "bottom-padding" : ""
          }`}
          src={hoverImage2Asset ? hoverImage2Asset : ""}
        />
      )}
      {hoverImage3Asset && (
        <Image
          popularLink
          className={`${activeImage === 3 ? "active" : ""} ${
            bottomPadding ? "bottom-padding" : ""
          }`}
          src={hoverImage3Asset ? hoverImage3Asset : ""}
        />
      )}
    </PopularLinkContainer>
  );
};

export default AnimationTile;

import {
  StyledExternalPopularLink,
  StyledPopularLink,
} from "./PopularLinkContainer.module";
import { externalRoute } from "utils";

export interface StyledPopularLinkProps {
  width?: string;
  color?: string;
  bg?: string;
  pd?: string;
  maxWidth?: string;
  className?: string;
  to: string;
  children: any;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  bottomPadding?: boolean;
  imageAlignment?: string;
  external?: boolean;
}

const PopularLinkContainer = ({
  to,
  className = "",
  children,
  onMouseEnter,
  onMouseLeave,
  bottomPadding,
  imageAlignment,
  external,
  ...rest
}: StyledPopularLinkProps) => {
  return external ? (
    <StyledExternalPopularLink
      to=""
      href={externalRoute(to)}
      rel="external noopener noreferrer"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`${className} ${bottomPadding ? "bottom-padding" : ""} ${
        imageAlignment === "right"
          ? "right"
          : imageAlignment === "center"
          ? "center"
          : ""
      }`}
      {...rest}
    >
      {children}
    </StyledExternalPopularLink>
  ) : (
    <StyledPopularLink
      to={to}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`${className} ${bottomPadding ? "bottom-padding" : ""} ${
        imageAlignment === "right"
          ? "right"
          : imageAlignment === "center"
          ? "center"
          : ""
      }`}
      {...rest}
    >
      {children}
    </StyledPopularLink>
  );
};

export default PopularLinkContainer;

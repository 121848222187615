import styled from "styled-components";
import { minTablet, minDesktop, zIndex } from "theme/variables";
import Form, { FormProps } from "components/forms/Form/Form";
import { Grid } from "@mui/material";

// if the height of the navbar changes in any way, must update the ProtectedPage.module file's min height for protected wrapper to match if there is a prop of guidelinesPage. this is a place-holder for the navbar on the guidelines page as it loads, so it does not jump, since guidelines page content loads faster than the navbar content
export const LoaderNavBar = styled.header`
  width: 100%;
  height: 3.5rem;
  /* position: fixed; */
  display: block;
  background-color: #F3EDE0;

  @media (min-width: ${minTablet}px) {
    height: 5.5rem;
  }
`;

export const StyledNavBar = styled.header`
  width: 100%;
  /* height: 5.5rem; */
  background-color: #F3EDE0;

  .header-spacer {
    position: fixed;
    height: 3.5rem;
    display: block;
    width: 100%;
    top: -3.5rem;
    transition: top 400ms ease-out;

    @media (min-width: ${minTablet}px) {
      height: 5.5rem;
      top: -5.5rem;
    }

    &.active {
      position: relative;
      top: 0;
      transition: top 400ms ease-out;
    }
  }

  .nav-header {
    position: relative;
    width: 100%;
    margin: auto;
    z-index: ${zIndex.navBar};
    height: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F3EDE0;
    color: #000;
    box-sizing: border-box;
    transition: top 400ms ease-out;

    &.fixed {
      position: fixed;
      top: -5.5rem;
      left: 0;
      @media (max-width: ${minDesktop}px) {
        top: -3.5rem;
      }
      transition: top 400ms ease-out;
      &.active {
        top: 0;
        transition: top 400ms ease-out;
      }
    }

    @media (max-width: ${minDesktop}px) {
      height: 3.5rem;
    }

    .nav {
      width: 100%;
      margin: auto;
      z-index: ${zIndex.navBar};
      height: 5.5rem;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 2rem;
      @media (max-width: ${minDesktop}px) {
        height: 3.5rem;
        padding-inline: 1.5rem;
      }

      .home-icon {
        width: fit-content;
        display: flex;
      }
    }

    a:focus-visible {
      outline: 1px solid #747676;
    }

    svg {
      height: 1.5rem;
      width: auto;
    }

    .header-nav-button {
      
      @media (min-width: ${minDesktop}px) {
        display: none;
      }
    }

    .logo {
      height: 1.5rem;
      width: auto;
      @media (min-width: ${minDesktop}px) {
        height: 2.5rem;
        width: auto;
      }
    }
  }

  .nav-header {
    .header-main-nav {
      height: 100%;
      flex: 1;
      z-index: ${zIndex.navBar};

      @media (max-width: ${minDesktop - 1}px) {
        width: 100%;
        height: 0;
        position: absolute;
        top: 3.5rem;
        left: 0;
        background: #F3EDE0;
        overflow: hidden;
        &.hidden-mobile-nav-active {
          visibility: hidden;
        }

        &.mobile-nav-active {
          visibility: visible;
          height: calc(100vh - 3.5rem);
          overflow: scroll;
        }
        transition: all 500ms ease-in-out;
      }
    }
    @media (max-width: ${minDesktop - 1}px) {
      .submit-work {
        padding: 0.5rem;
      }
    }
  }

  .header-main-nav-list {
    margin: 0;
    padding: 0.5rem 1.25rem 10rem;
    list-style: none;
    width: 100%;
    overflow-y: auto;

    @media (min-width: ${minDesktop}px) {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 0;
    }
  }

  .header-main-nav-item-container {
    padding: 0.875rem 0;
    border-bottom: 1px solid #747676;
    box-sizing: border-box;
    width: 100%;
    div {
      a {
        div {
          p {
            letter-spacing: 0;
          }
        }
      }
      .header-main-nav-item-link {
        p {
          letter-spacing: 0;
        }
      }
    }

    @media (min-width: ${minDesktop}px) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem 0.5rem;
      border: none;
    }

    &.mobile-only {
      @media (max-width: ${minDesktop - 1}px) {
        display: flex;
      }
      @media (min-width: ${minDesktop}px) {
        display: none;
      }
    }
  }
  
  .header-main-nav-item-link {
    + div {
      transition: transform 250ms;
    }
  }
  .sub-nav-active {
    + div {
      transform: rotate(-180deg);
    }
  }

  .header-main-nav-item {
    @media (max-width: ${minDesktop - 1}px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &.mobile-only {
      @media (min-width: ${minDesktop}px) {
        display: none;
      }
    }

    .sub-nav-active + button {
      svg {
        transform: rotate(-180deg);
        transition: transform 250ms;
      }
    }

    button {
      @media (min-width: ${minDesktop}px) {
        display: none;
      }
    }
  }

  .header-main-nav-item:hover,
  .header-main-nav-item.active,
  .header-main-nav-item:focus-within {
    .header-main-nav-item-link {
      border-bottom-color: #ee0000;

      &:not(.not-link) {
        cursor: pointer;
      }
      &.not-link {
        cursor: default;
      }
    }
  }

  .header-main-nav-item-link {
    width: 100%;
    padding: 0.125rem 0;
    font-weight: bold;

    &,
    &:hover,
    &:focus {
      outline: none;
      color: inherit;
      text-decoration: none;
    }

    @media (min-width: ${minDesktop}px) {
      border-bottom: 0.125rem solid transparent;

      &:focus {
        border-bottom-color: #ee0000;
      }
      &.mobile-only {
        display: none;
      }
    }
  }

  .header-sub-nav {
    height: 0;
    width: 100%;
    padding: 0;
    background-color: #F3EDE0;
    color: transparent;
    overflow: hidden;
    transition: height 500ms, color 500ms;
    display: block;

    &.hidden-subnav {
      display: none;
    }

    @media (max-width: ${minDesktop - 1}px) {
      transition: height 400ms, color 400ms;
    }

    @media (min-width: ${minDesktop}px) {
      position: absolute;
      left: 0;
      top: 4.5rem;
    }
  }

  .sub-nav-active {
    .header-sub-nav {
      color: inherit;
      visibility: visible;
      padding-left: 0;
      a {
        visibility: visible;
      }
    }
  }

  .hidden-subnav {
    .header-sub-nav {
      visibility: hidden;
      a {
        visibility: hidden;
        &.hidden-link {
          display: none;
        }
      }
    }
  }

  .header-sub-nav-list {
    list-style: none;
    margin: 0;
    padding: 1rem;

    @media (min-width: ${minDesktop}px) {
      padding: 0 0 2rem;
    }
  }

  .header-sub-nav-item {
    white-space: nowrap;
    list-style: none;

    width: fit-content;

    & + .header-sub-nav-item {
      margin-top: 0.75rem;
    }

    .header-sub-nav-item-link {
      letter-spacing: 0;
      &:hover,
      &:focus,
      &:active,
      &.active,
      &:focus-visible {
        outline: none;
        color: #000;
        text-decoration: none;
        p {
          font-weight: bold;
          font-family: Verizon-NHG-eDS, "Helvetica Neue", helvetica, arial,
            sans-serif;
        }
      }
    }
  }

  .header-user-nav-list li.header-user-nav-item-secondary {
    list-style: none;
  }

  .header-sub-nav-item-link {
    &.hover {
      p {
        font-weight: bold;
        font-family: Verizon-NHG-eDS, "Helvetica Neue", helvetica, arial,
          sans-serif;
      }
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: ${zIndex.globalBackdrop};
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    pointer-events: none;
    transition: opacity 500ms;

    &.active {
      opacity: 1;
    }
  }

  .nav-header {
    .nav-utilities {
      .search-button-desktop-container {
        display: none;
        @media (min-width: ${minDesktop}px) {
          display: flex;
        }
      }
      .search-icon-container {
        svg {
          height: 1rem;
        }
      }
      .submit-work {
        display: none;
        @media (min-width: ${minDesktop}px) {
          display: flex;
        }
      }
    }
  }
`;

export const StyledMenuWrapper = styled.div`
  position: relative;
  height: 100%;

  > button {
    height: 100%;
  }
  display: flex;
  align-items: center;

  @media screen and (max-width: ${minDesktop}px) {
    display: none;
  }
`;

export const UserMenu = styled.div`
  z-index: ${zIndex.navBar};

  position: absolute;
  top: 100%;
  right: -1rem;
  width: fit-content;
  min-width: 13rem;
  white-space: nowrap;
  /* height: 25rem; */

  visibility: hidden;
  opacity: 0;
  transition: all 250ms;

  padding-top: 0.25rem;

  transform: translateY(-1rem);
  text-align: left;

  &.open {
    visibility: visible;
    opacity: 1;
    transform: none;
  }

  > .menu-list {
    background-color: #F3EDE0;
    list-style: none;
    margin: 0;
    padding: 0;

    li + li {
      border-top: 1px solid #747676;
    }

    > li > div.header {
      background-color: #F3EDE0;

      border-top: 1px solid #747676;

      &:first-of-type {
        border-top: none;
      }

      padding: 0.75rem 2rem 0.75rem 0.75rem;

      &.nested {
        background: #F3EDE0;
        p {
          padding-left: 1rem;
        }
      }

      &.sign-out:hover {
        cursor: pointer;
      }
    }

    > .item {
      background: #F3EDE0;
      border-top: 1px solid #747676;
      &:first-of-type {
        border-top: none;
      }

      a {
        text-decoration: none;
        display: block;
        padding: 0.75rem 2rem 0.75rem 1.5rem;
        &:hover,
        &:focus,
        &:active,
        &:focus-visible {
          outline: none;
          color: #000;
          text-decoration: none;
          p {
            font-weight: bold;
            font-family: Verizon-NHG-eDS, "Helvetica Neue", helvetica, arial,
              sans-serif;
          }
        }
      }
    }
  }

  ul {
    list-style: none;
    #sign-out {
      button {
        padding: 0.75rem 2rem 0.75rem 0.75rem;
        background-color: transparent;
        margin: 0;
        width: 100%;
        height: 100%;
        border: none;
        text-align: left;
        > span:last-child {
          text-align: left;
          font-weight: normal;
        }
        &:hover,
        &:focus,
        &:focus-within,
        &:active,
        &:visited,
        &:focus:not(:hover) {
          border: none;
          border-radius: unset;
          box-shadow: unset;
          span:last-child {
            text-align: left;
            font-weight: bold;
            &:hover,
            &:focus,
            &:focus-within,
            &:active,
            &:visited,
            &:focus:not(:hover) {
              font-weight: bold;
            }
            &:before {
              border: none;
              border-radius: unset;
              box-shadow: unset;
              width: 0;
              height: 0;
            }
          }
        }
        &:focus:not(:hover):not(:disabled) {
          &::before {
            border: none;
            border-radius: unset;
            box-shadow: unset;
            width: 0;
            height: 0;
          }
        }
        span {
          padding: 0;
        }
        span:last-child {
          color: #000;
        }
      }
    }
    .nested-menu {
      padding-inline: 0;
      &.double-nested {
        p {
          padding-left: 0.75rem;
        }
      }
      .nested {
        background: #F3EDE0;
        border-top: 1px solid #747676;
        .header.nested {
          border-top: none;
        }
        .header p {
          padding: 0.75rem 2rem 0.75rem 1.5rem;
        }
      }
    }
  }
`;

export const SearchSection = styled.section`
  z-index: ${zIndex.searchBar};
  position: relative;
`;

export const SearchBar = styled.div`
  position: relative;
  padding-block: 2.25rem;
  z-index: ${zIndex.notBottom};

  background-color: #f6f6f6;
`;

export const SearchForm = styled(Form)<FormProps>`
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  margin: auto;

  padding: 0 1.25rem;
  max-width: 1440px;
  > input {
    font-family: Verizon-NHG-eDS, "Helvetica Neue", helvetica, arial, sans-serif;
    font-weight: bold;

    height: 3.5rem;
    font-size: 3rem;

    background-color: transparent;
    border: none;

    width: 100%;

    &:focus {
      outline: none;
    }
  }
`;

export const SearchDropdown = styled.div`
  visibility: hidden;
  opacity: 0;
  transform: translateY(-100%);

  background-color: #ffffff;
  height: fit-content;

  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;

  &.open {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  transition: visibility 200ms ease-in-out, transform 200ms ease-in-out,
    opacity 200ms ease-in-out;
`;

export const SearchBackdrop = styled.div`
  z-index: ${zIndex.backdrop};
  visibility: hidden;
  opacity: 0;

  background-color: rgba(116, 118, 118, 0.7);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.open {
    visibility: visible;
    opacity: 1;
  }

  transition: visibility 200ms ease-in-out, opacity 200ms ease-in-out;
`;

export const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  width: fit-content;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
  }

  .suggested {
    cursor: pointer;
    &:hover {
      p {
        font-weight: 700;
      }
    }
  }

  .recent {
    &:not([disabled]):hover {
      cursor: pointer;
      p {
        color: #000000;
      }
    }
  }

  .delete-button {
    margin-left: 1.5rem;
    &:not([disabled]):hover {
      cursor: pointer;
      path {
        stroke: black;
      }
    }
  }
`;

export const StyledSignOutButton = styled.button`
  padding: 0;
  background-color: transparent;
  margin: 0;
  width: 100%;
  height: 100%;
  border: none;
  text-align: left;
  p {
    text-align: left;
    font-weight: normal;
  }
  &:hover,
  &:focus,
  &:focus-within,
  &:active,
  &:visited,
  &:focus:not(:hover) {
    border: none;
    border-radius: unset;
    box-shadow: unset;
    cursor: pointer;
    p {
      text-align: left;
      font-weight: bold;
      &:hover,
      &:focus,
      &:focus-within,
      &:active,
      &:visited,
      &:focus:not(:hover) {
        font-weight: bold;
      }
      &:before {
        border: none;
        border-radius: unset;
        box-shadow: unset;
        width: 0;
        height: 0;
      }
    }
  }
  &:focus:not(:hover):not(:disabled) {
    &::before {
      border: none;
      border-radius: unset;
      box-shadow: unset;
      width: 0;
      height: 0;
    }
  }
  p {
    padding: 0;
  }
`;

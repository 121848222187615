import styled from "styled-components";
import {desktop, minDesktop, minMobileLarge, minTablet, mobile, mobileLarge, tablet, tabletLarge, zIndex} from "theme/variables";
import Form, { FormProps } from "components/forms/Form/Form";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import FlexBox from "../../components/layouts/FlexBox/FlexBox";
import ThreeUp from "../../components/layouts/ThreeUp/ThreeUp";

export const SearchSection = styled.section`
  transform: translateY(-100%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  visibility: hidden;

  &.open {
    /* opacity: 1; */
    transform: translateY(0);

    visibility: visible;
  }
  z-index: ${zIndex.globalSearch};
  background-color: #F3EDE0;

  transition: visibility 200ms ease-in-out, transform 200ms ease-in-out;
  .home-icon {
    width: fit-content;
    display: flex;
  }
`;

export const SearchLogo = styled(FlexBox)`
  height: 5.5rem;
  padding-inline: 2rem;
  min-width: 100%;
  @media (max-width: ${minDesktop}px) {
    height: 3.5rem;
    padding-inline: 1.5rem;
  }
  .logo {
    height: 1.5rem;
    width: auto;
    @media (min-width: ${minDesktop}px) {
      height: 2.5rem;
      width: auto;
    }
  }
`;

export const CloseContainer = styled(FlexBox)`
  justify-content: flex-end;
`;

export const SearchBarContainer = styled(Grid)`
  width: 100%;
  background-color: #F3EDE0;
  padding-inline: 1.25rem;
  min-width: 100%;
  @media (max-width: ${minDesktop}px) {
    padding-inline: 1rem;
  }
`;

export const SearchBar = styled(Grid)`
  position: relative;
  padding-top: 2.25rem;
  z-index: ${zIndex.notBottom};

  background-color: #F3EDE0;
  border-bottom: 1px solid #000000;
`;

export const SearchForm = styled(Form)<FormProps>`
  flex-direction: row;
  align-items: center;

  margin: auto;
  
  padding: 0;
  
  max-width: 1440px;

  @media screen and (max-width: ${minMobileLarge - 1}px) {
    gap: 1.25rem;
  }
  @media screen and (min-width: ${minMobileLarge}px) {
    gap: 1.5rem;
  }
  
  > input {
    font-family: Verizon-NHG-eDS, "Helvetica Neue", helvetica, arial, sans-serif;
    font-weight: bold;

    height: 3.5rem;

    background-color: transparent;
    border: none;
    color: #000000;

    width: 100%;
    @media screen and (max-width: ${minMobileLarge - 1}px) {
      font-size: 1.5rem;
    }
    @media screen and (min-width: ${minMobileLarge}px) {
      font-size: 2.25rem;
    }

    &:focus {
      outline: none;
    }
  }
  > input::placeholder {
    color: #F50A23;
  }
`;

export const StyledRecentSearchesGrid = styled(Grid)`
  @media screen and (max-width: ${minTablet - 1}px) {
    //padding: 1.25rem;
  }
  @media screen and (min-width: ${minTablet}px) {
    //padding: 1.25rem 0 1.25rem 4.25rem;
  }
`;

export const StyledRecentSearchesList = styled.ul`
  list-style-type: none;
  padding: 0 1rem 0 0;
  margin: 0;

  width: fit-content;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.25rem;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
  }

  .recent {
    p {
      color: #000000;
    }
    &:not([disabled]):hover {
      cursor: pointer;
      p {
        font-weight: bold;
      }
    }
  }

  .delete-button {
    margin-left: 1.5rem;
    &:not([disabled]):hover {
      cursor: pointer;
      path {
        stroke: black;
      }
    }
  }
`;

export const SearchDropdown = styled.div`
  visibility: hidden;
  opacity: 0;
  transform: translateY(-100%);

  background-color: #F3EDE0;
  height: fit-content;

  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;

  &.open {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  transition: visibility 200ms ease-in-out, transform 200ms ease-in-out,
    opacity 200ms ease-in-out;
`;

export const SearchBackdrop = styled.div`
  z-index: ${zIndex.globalBackdrop};
  visibility: hidden;
  opacity: 0;

  background-color: rgba(0, 0, 0, 0.7);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.open {
    visibility: visible;
    opacity: 1;
  }

  transition: visibility 200ms ease-in-out, opacity 200ms ease-in-out;
`;

// export const StyledGrid = styled(Grid)`
//   position: relative;

//   &:after {
//     content: "";
//     border-bottom: 1px solid #d8dada;

//     position: absolute;
//     bottom: 0;
//     left: 1.5rem;
//     right: 1.5rem;
//   }
// `;

export const SectionWrapper = styled.div`
  @media ${mobile} {
    padding: 2.625rem 1rem 3.625rem;
  }
  @media ${mobileLarge} {
    padding: 2.625rem 1rem 3.625rem;
  }
  @media ${tablet} {
    padding: 2.625rem 1rem 3.625rem;
  }
  @media ${tabletLarge} {
    padding: 2.625rem 1rem 3.625rem;
  }
  @media ${desktop} {
    padding: 2.625rem 1.25rem 3.625rem;
  }
  @media screen and (min-width: 1440px) {
    padding: 2.625rem 0 3.625rem;
  }
  
`;

// export const StyledList = styled.ul<{ border?: boolean }>`
//   list-style-type: none;
//   padding: 0;
//   margin: 0;
//
//   width: 100%;
//
//   ${(props) =>
//     props.border
//       ? `border-top: 1px solid #d8dada;
//          padding-top: 1.25rem;
//          margin-top: 1.5rem;`
//       : ``};
//
//   span {
//     font-weight: 700;
//   }
//   li {
//     .suggested {
//       cursor: pointer;
//       p {
//         color: #a7a7a7;
//       }
//       &:hover {
//         p {
//           color: #ffffff;
//         }
//       }
//     }
//   }
//
// `;

export const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  width: fit-content;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.25rem;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
  }

  .suggested {
    cursor: pointer;
    color: #000000;
    &:hover {
      p {
        font-weight: 700;
        color: #000000;
      }
    }
  }

  .delete-button {
    margin-left: 1.5rem;
    &:not([disabled]):hover {
      cursor: pointer;
      path {
        stroke: white;
      }
    }
  }
`;

export const StyledSavedItem = styled.li`
  :not(:first-of-type) {
    margin-top: 1.5rem;
  }
`;

export const ButtonWrapper = styled.button`
  display: block;
  border: none;
  background-color: transparent;
  width: fit-content;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #000000;
    text-decoration-thickness: 1px;
  }
`;

export const LinkWrapper = styled(({ secondary, ...rest }) => (
  <Link {...rest} />
))`
  display: block;
  text-decoration: none;
  width: fit-content;

  &:hover {
    text-decoration: underline;
    text-decoration-color: #000000;
    text-decoration-thickness: 1px;

    text-decoration-color: ${(props) =>
      props.secondary ? `#747676` : `#000000`};
  }
`;

export const StyledSearchItem = styled.li`
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  flex-direction: column;

  :not(:first-of-type) {
    margin-top: 0.5rem;
  }

  span {
    font-weight: 700;
  }
`;

export const ImageWrapper = styled.div`
  height: 15rem;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #f6f6f6;
  border-radius: 0.5rem;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
export const ExternalLink = styled.a`
  color: #000000;
  text-decoration: none;
`;

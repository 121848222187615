import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { StyledPopularLinkProps } from "./PopularLinkContainer";

const baseLinkStyle = css`
  height: 20rem;
  display: block;
  position: relative;
  background-color: #F3EDE0;
  //font-family: fonts.$display_bold;
  //font-weight: normal;
  text-decoration: none;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
  border-radius: 1.5rem;
  overflow: hidden;
  & > div > div > div {
    color: #000000;
  }

  &:hover {
    transform: scale(1.025);
    transition: background-color 600ms, transform 600ms;
    border: 0.5px solid #A7A7A7;

    text-decoration: none;
    background-color: #ffffff;
    & > div > div > div {
      color: #000000;
    }
  }

  &:focus {
    text-decoration: underline;
    text-decoration-color: #000000;
    outline: none;
    background-color: #ffffff;
    border: 0.5px solid #A7A7A7;
    & > div > div > div {
      color: #000000;
    }
  }

  :host[background-color="BaseGray"] & {
    background-color: #F3EDE0;
  }

  :host[background-color="Gray"] & {
    background-color: #F3EDE0;
  }

  :host[background-color="Red"] & {
    background-color: #F3EDE0;

    &:hover {
      background-color: #ffffff;
      color: #000000;
      border: 0.5px solid #A7A7A7;
    }
  }
  &.right {
    img {
      right: 1.5rem;
      left: unset;
    }
  }

  &.center {
    img {
      right: 0;
      left: 0;
      margin: auto;
    }
  }
`;

export const StyledPopularLink = styled(Link)<StyledPopularLinkProps>`
  ${baseLinkStyle}
`;

export const StyledExternalPopularLink = styled.a<StyledPopularLinkProps>`
  ${baseLinkStyle}
`;
